/*

d) The Standard Bootstrap 4.x Breakpoints
@media all and (max-width: 1199px) { ... } 
@media all and (max-width: 991px) { ... } 
@media all and (max-width: 768px) { ... } 
@media all and (max-width: 575px) { ... }
 
e) The Material Design Lite (MDL) Breakpoints 

@media all and (max-width: 1024px) { ... } 
@media all and (max-width: 839px) { ... } 
@media all and (max-width: 480px) { ... }


*/

/***************/
/**   Fonts   **/
/***************/
@font-face {
  font-family: "RalewayBlack";
  src: local("Raleway-Black"),
    url(./fonts/Raleway/Raleway-Black.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoBlack";
  src: local("Roboto-Black"),
    url(./fonts/Roboto/Roboto-Black.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoMedium";
  src: local("Roboto-Medium"),
    url(./fonts/Roboto/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoLight";
  src: local("Roboto-Light"),
    url(./fonts/Roboto/Roboto-Light.ttf) format("truetype");
}

/*******************/
/**   Palette    **/
/*****************/

.fill1 {
  background-color: #2982a2;
}
.color1 {
  color: #2982a2;
}

.fill1-a {
  background-color: #199ebf;
}
.color1-a {
  color: #199ebf;
}

.fill2 {
  background-color: #e3743d;
}
.color2 {
  color: #e3743d;
}
.fill3 {
  background-color: #c89d00;
}
.color3 {
  color: #c89d00;
}

.fill4 {
  background-color: #555;
}
.color4 {
  color: #555;
}

/***************/
/**   Base    **/
/***************/
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #000;
  font-family: "Roboto", Arial, Helvetica;
}

p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

#header {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  height: 70px;
  /* padding: 10px; */
  background-color: #ffffff;
  /* opacity: 0.9; */
  filter: drop-shadow(10px 0px 15px #444444);
}

.mainNavButton {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding-right: 20px;
  padding-left: 20px;
  cursor: pointer;
}

.mainNavOverlay {
  height: 100%;
  width: 100%;
  background-color: #000;
}

.slide-pane__content {
  background: #000;
}

.menuItem {
  font-family: "RalewayBlack";
  color: #fff;
  font-family: "RalewayBlack";
  font-size: 30px;
}
.menuItemText {
  text-align: center;
  cursor: pointer;
  padding: 15px;
}

#navMenu_home:hover,
#navMenu_who:hover,
#navMenu_what:hover,
#navMenu_work:hover,
#navMenu_contact:hover {
  color: #c89d00;
}

.primaryBtn {
  background-color: #2982a2; /* fill1 */
}

#header-content {
  display: flex;
  flex-direction: row;
}

.header-left {
  display: flex;
  height: 100%;
  padding-left: 30px;
  flex: 1 0 auto;
}

.header-right {
  padding-right: 20px;
  font-size: 0.7em;
  text-transform: uppercase;
  color: #000000;
  display: flex;
  flex: 1 0 auto;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
}

.logo {
  font-family: "RalewayBlack", Arial, Helvetica;
  font-size: 25px;
  color: #000000;
}

.page {
  justify-content: "center";
  align-items: "center";
  margin: 65px auto 0 auto;
}

.banner {
  position: relative;
  /* max-width: 900px; */
  margin: 0 auto 0 auto;
}

.banner-headerText {
  position: absolute;
  top: 0;
  left: 0;
  font-family: "RobotoMedium", Arial, Helvetica;
  font-size: 40px;
  width: 500px;
  padding: 100px 0 0 40px;
}

.worldDotMap {
  background: #eee url("./images/worldDotMapGrey.png") center 0 no-repeat;
  background-attachment: fixed;
  background-size: 150% auto;
  opacity: 0.3;
  height: 350px;
  max-height: 350px;
  overflow: hidden;
}

.fulfillNeedsText {
  font-family: "RobotoMedium", Arial, Helvetica;
  font-size: 20pt;
}

.fulfillNeedsBtn {
  background-color: #2982a2;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  font-family: "RobotoMedium", Arial, Helvetica;
  font-size: 16pt;
}

/*******************/
/**   Card Box    **/
/*******************/

.cardBoxHolder {
  display: flex;
  margin: 20px 40px 20px 40px;
  justify-content: space-between; /* space-around; */
  flex-direction: "row";
  align-items: "center";
}

.cardBox {
  border: solid 1px #ccc;
  /* flex-grow: 1; */
  width: 32%;
  min-height: 300px;
  background-color: #fff;
}

.cardBoxHead {
  height: 100px;
  width: 100%;
  border: solid 1px #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.cardBoxInfo {
  padding: 15px;
}

.cardBoxTitle {
  font-family: "RobotoMedium";
  font-size: 1.2em;
  margin: 0 0 20px 0;
}

.cardBoxText {
  font-family: "RobotoLight";
  font-size: 1.2em;
  line-height: 1.7em;
  margin: 0 0 5px 0;
}

.cardBox1 {
  /* margin: 0 10px 0 0; */
}
.cardBox2 {
  /* margin: 0 10px 0 10px; */
}
.cardBox3 {
  /* margin: 0 0px 0 10px; */
}

.listBoxHolder {
  display: flex;
  margin: 20px 40px 20px 40px;
  justify-content: space-between; /* space-around; */
  flex-direction: "row";
  align-items: "center";
}

.listBox {
  border: solid 1px #fff;
  /* flex-grow: 1; */
  width: 32%;
  min-height: 300px;
  background-color: #fff;
}

.listBoxInfo {
  padding: 15px;
}

.listItem {
  margin: 0 0 20px 0;
}

.listTitle {
  font-family: "RobotoMedium";
  font-size: 1.2em;
  margin: 0 0 5px 0;
}

.listTextBox {
  display: none;
}

.listText {
  font-family: "RobotoLight";
  font-size: 1.2em;
  line-height: 1.2em;
  margin: 0 0 5px 0;
}

.portSelectedList {
  display: none;
}

.portSelectedIndustry {
  display: block;
}

.divideSM {
  width: 100px;
  height: 2px;
  background-color: #e3743d;
}

.expandHeading {
  font-family: "RobotoBlack";
}

.expandDetail {
  font-family: "RobotoLight";
  flex-direction: column;
}

/*******************/
/**   Flex   **/
/******************/

.flex {
  display: flex;
}
.flexRow {
  flex-direction: row;
}

.flexSpaceBetween {
  justify-content: space-between;
}

/*******************/
/**   Utilities   **/
/******************/

.show {
  display: block;
}
.hide {
  display: none;
}

.errorText {
  color: #d8000c;
}

.centerChildren {
  justify-content: center;
  align-items: center;
}

.centerH {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.marginBot30 {
  margin-bottom: 30px;
}

.test {
  border: solid 1px red;
}
.test2 {
  border: solid 1px green;
}
.test3 {
  border: solid 1px blue;
}
/*
 display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
*/

/*******************/
/**   Sections   **/
/******************/

.aboutBg {
  position: relative;
  background: #eee url("./images/aboutBg.jpg") center 0 no-repeat;
  background-position-y: -190px;
  /* background-attachment: fixed; */
  background-size: 100% auto;
  /*
  height: 400px;
  max-height: 400px;
  */
  overflow: hidden;
}

.careersBg {
  background: #fff;
}

.workBg {
  position: relative;
  background: #eee url("./images/workBg.jpg") center 0 no-repeat;
  background-position-y: -190px;
  /* background-attachment: fixed; */
  background-size: 100% auto;
  /*
  height: 400px;
  max-height: 400px;
  */
  overflow: hidden;
}

.ideasBg {
  padding: 10px;
}

.aboutTextBg,
.workTextBg,
.sectionBg {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 20px 20px 20px;
  padding: 60px 40px 80px 40px;
  background-color: #fff;
  opacity: 0.9;
}

/* depricate for .sectionTitle */
.aboutTitle,
.workTitle {
  font-family: "RobotoLight";
  font-size: 2em;
  margin: 0 0 20px 0;
}

.sectionBg {
  position: relative;
  background: #fff;
  overflow: hidden;
}

.sectionTextBg {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 20px 20px 20px;
  padding: 60px 40px 80px 40px;
  background-color: #eee;
}

.sectionTextBgAlt {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 20px 20px 20px;
  padding: 0px 40px 80px 40px;
  background-color: #fff;
}

.sectionTitle {
  /* font-family: "RobotoBlack"; */
  font-family: "RobotoMedium";
  font-size: 2.1em;
  margin: 0 0 20px 0;
}

.sectionTitle2 {
  /* font-family: "RobotoBlack"; */
  font-family: "RobotoMedium";
  font-size: 1.7em;
  margin: 0 0 20px 0;
}

.sectionTitle3 {
  /* font-family: "RobotoBlack"; */
  font-family: "RobotoMedium";
  font-size: 26px;
  margin: 0 0 20px 0;
}

.sectionSubTitle {
  font-family: "RobotoMedium";
  font-size: 1.4em;
  line-height: 2em;
  margin: 0 0 30px 0;
}

.sectionText {
  font-family: "RobotoLight";
  font-size: 1.4em;
  line-height: 2em;
}

.aboutText,
.workText {
  font-family: "RobotoLight";
  font-size: 1.4em;
  line-height: 2em;
}

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #555;
  height: 200px;
  /* justify-content: center; */
  align-items: center;
  padding: 40px 0 0 0;
}

.footerLinks {
  justify-content: space-between;
  width: 500px;
  display: flex;
  flex-direction: row;
  margin: 0 0 20px 0;
}

.footerMainLink {
  color: #fff;
}

.footerMainLink:hover {
  color: #c89d00;
}

.copyrightBar {
  position: absolute;
  display: flex;
  height: 50px;
  width: 100%;
  text-align: center;
  bottom: 0px;
  justify-content: center;
  align-items: center;
  background: #000;
  color: #999;
  font-size: 12px;
  font-weight: bold;
}

.contactFormHolder {
  min-width: 400px;
  max-width: 900px;
}

.portText {
  width: 80%;
  margin: 0 auto 0 auto;
  font-size: 1.5em;
}

/*******************/
/*  IDEAS SECTION  */
/*******************/

.ideasBox {
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 70px 0 70px 0;
}

.ideasTitle {
  font-family: "Copernicus-MediumItalic", Georgia, Times, "Times New Roman",
    serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  padding: 0 40px 0 0;
  color: #fff;
}
.ideasEmailBox {
  background: #fff;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.ideasEmailTB {
  border: none;
  background: #fff;
  font-size: 20px;
  vertical-align: middle;
  margin: 0 auto;
  text-align: center;
  display: block;
}

.ideasAction {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.ideasSubscribeBtn {
  color: #fff;
  font-family: "RobotoBlack";
  background-color: #000;
  padding: 5px 15px 5px 15px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  outline-width: 0;
  outline: none;
}

.ideasSubscribeBtn:hover {
  background-color: #555;
  outline-width: 0;
  outline: none;
}

.ideasSubscribeBtn:active {
  background-color: #888;
  outline-width: 0;
  outline: none;
}

/*
.ideasSubscribeBtn:focus {
  background-color: #555;
  outline-width: 0;
  outline: none;
}

.ideasSubscribeBtn:blur {
  background-color: #000;
  outline-width: 0;
  outline: none;
}
*/

.ideasSubscribeBtnText {
  margin: auto auto auto auto;
  user-select: none;
}

.aboutBg,
.workBg {
  height: auto;
  max-height: none;
  overflow: none;
  background-size: cover;
  background-position-y: 0;
}

/*********************/
/*  CAREERS SECTION  */
/*********************/

.jobsCategoryOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px #ccc;
  padding: 10px 2px 10px 6px;
  cursor: pointer;
}

.jobsCategoryOptionTop {
  border-top: solid 1px #ccc;
}

.jobsCategoryOption:hover {
  background-color: #eee;
}

.jobsCategoryLink {
  font-weight: 600;
  padding: 0 0 0 6px;
}

.jobsCategoryLinkArrow {
  color: #777;
  padding: 0 10px 0 0;
}

/*****************/
/*  BREAKPOINTS  */
/*****************/

@media all and (max-width: 1024px) {
  .contactFormHolder {
    min-width: 400px;
    max-width: 900px;
  }
}

/**********************************/
/***            839             ***/
/**********************************/
@media all and (max-width: 839px) {
  .cardBoxHolder {
    justify-content: center;
    flex-direction: column;
  }

  .contactFormHolder {
    min-width: 400px;
    max-width: 900px;
  }

  .cardBox {
    width: 100%;
    margin-bottom: 30px;
  }

  .ideasBox {
    flex-direction: column;
    text-align: center;
  }

  .ideasTitle {
    margin: 0 0 40px 0;
  }

  .portSelectedList {
    display: none;
  }

  .listBox {
    width: 100%;
    margin: 0;
    padding: 0;
    min-height: auto;
  }

  .listBoxInfo {
    padding: 0px;
  }

  .listTextBox {
    display: none;
  }

  .portSelectedIndustry {
    display: block;
  }

  .aboutBg,
  .workBg {
    height: auto;
    max-height: none;
    overflow: none;
    background-size: auto 100%;
    background-position-y: 0;
  }
} /* end */

/**********************************/
/***            480             ***/
/**********************************/
@media all and (max-width: 480px) {
  .banner-headerText {
    width: 450px;
  }

  .homeLine1 {
    font-size: 20px;
    margin: 40px 0 0 0;
  }
  .homeLine2,
  .homeLine3 {
    display: none;
  }

  .cardBoxHolder {
    flex-direction: column;
  }

  .cardBox {
    width: 100%;
    margin-bottom: 30px;
  }

  .aboutBg,
  .workBg {
    height: auto;
    max-height: none;
    overflow: none;
    background-size: auto 100%;
    background-position-y: 0;
  }

  .sectionBg {
    margin: 10px;
  }

  .contactFormHolder {
    min-width: auto;
    max-width: 900px;
  }

  .ideasBox {
    flex-direction: column;
  }

  .portSelectedList {
    display: none;
  }

  .listBoxHolder {
    flex-direction: column;
    text-align: center;
  }

  .listBox {
    width: 100%;
  }

  .listBoxInfo {
    padding: 0px;
  }

  .listTextBox {
    display: none;
  }

  .portSelectedIndustry {
    display: block;
  }

  .footerLinks {
    width: 320px;
  }
}
